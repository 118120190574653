<template>

  <div v-if="isShow">
    <Loading/>
  </div>

  <div class="page-banner" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="overlay">
      <div class="trapezoidal-block">
        <h1>{{ $t("product") }}</h1>
        <div class="small-nav">
          <router-link to="/">
            {{ $t("home") }}
          </router-link>
          <span>丨</span>
          <router-link to="/products">
            {{ $t("product") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="minHeight middle common-padding-100">
    <div class="product">

      <div class="pro-intro">
        <div class="intro-txt">
          <h3>{{ cate.title }}</h3>
          <p>{{ cate.subheading }}</p>
        </div>
        <div class="common-btn">
          <div>
            <a :href="cate.pdf" target="_blank">
              {{ $t("introduction") }} <span><i class="iconfont icon-youjiantou"></i></span>
            </a>
          </div>
        </div>
      </div>

      <div class="pro-list">
        <ul>
          <li class="common-item"
              v-for="item in list"
              :key="item.id"
              @click="details(item.id, item.title)"
          >
            <div class="common-item-img">
              <img :src="item.cover">
            </div>

            <div class="common-item-txt">
              <h3>{{ item.title }}</h3>
              <p>{{item.subheading}}</p>
            </div>
          </li>
        </ul>
      </div>

      <Pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          :show-pagination="showPagination"
          @goto-page="onPageChange"
      />

    </div>
  </div>
  <FooterInquiry></FooterInquiry>
</template>

<script>
import { ref } from 'vue'
import FooterInquiry from "@/components/FooterInquiry";
import Loading from "@/components/loading";
import Pagination from "@/components/Pagination";
import {productList, productCategories} from "@/network/main";

export default {
  name: "ProductsList",
  components:{
    FooterInquiry,
    Loading,
    Pagination
  },
  setup() {
    const meta = ref({
      title: "",
      keywords: "",
      description: "",
      baiduSiteVerification : ""
    });
    return {
      meta
    }
  },
  metaInfo () {
    return {
      title:"Products - OSONG ELECTRIC Co. LTD",
      meta: [
        {
          name: 'description',
          content: this.seo
        }
      ],
    }
  },
  data(){
    return{
      isShow:true,
      banner:'',
      list:[],
      id:null,
      cate:{},
      seo:'',
      currentPage: 1,
      totalPages: 1,
      showPagination: true,
      title:'',
    }
  },
  created() {
    this.getCate()
    this.id = this.$route.params.id; //拿到id及title
    this.title = this.$route.params.title;
    this.getInfo(this.id,this.currentPage)
  },
  methods:{
    //cate
    getCate(){
      productCategories().then(res =>{
        this.banner = res.data.data.banner;
        this.seo = res.data.data.seo;
      })
    },
    //
    getInfo(id,page) {
      productList(id,page).then(res =>{
        this.isShow = false;
        this.list = res.data.data.list;
        this.cate = res.data.data.product_type;
        this.totalPages = res.data.data.last_page;
        this.showPagination = this.totalPages > 1;
      })
    },
    //分页
    onPageChange(page) {
      this.currentPage = page;
      this.getInfo(this.id,this.currentPage)
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    //跳转传递参数到$router
    details(id, title){
       this.$router.push({ name: 'Details',  params: { id: id, title: title } });
    },
  },
}
</script>

<style scoped>

</style>